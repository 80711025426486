import { Injectable, OnInit } from '@angular/core';
import { ErrorMessage } from '../model/error-message';
import { StudySetupService } from '../services/study-setup.service';
import { Subscription } from 'rxjs';
import * as globalConst from 'app/common/model/app-constants';
import * as moment from 'moment';
import { EventArg } from '../model/event-arg';
import { AppInjector } from 'app/app-injector';
import { UserService } from '../services/user.service';
import { Code } from '../model/code';
import { DatePipe } from '@angular/common';

@Injectable()
export abstract class BaseComponent implements OnInit {



    /**
     * Array used to display error messages
     */
    errorMessages: Array<ErrorMessage> = new Array<ErrorMessage>();

    /**
     * groups the error messages by the studySchemaEventId
     */
    // groupedErrors: Map<number, Array<string>> = new Map<number, Array<string>>();


    /**
     * variable to hold the ids for error messages being displayed
     */
    errDisplayIds: Array<number> = new Array<number>();


    /**
     * Subscription array to hold each subscription so they can be closed upon destruction
     */
    subscriptions: Subscription[] = [];

    studySetupService: StudySetupService;

    usrService: UserService;

    datePipe: DatePipe

            /**
   * Configuration for the table displayed
   */
            public config: any = {
                paging: false,
                sorting: false, 
                filtering: false, 
                className: ['is-striped']
              };

    constructor() {
        try {
            const injector = AppInjector.getInjector();
            this.studySetupService = injector.get(StudySetupService);
            this.usrService = injector.get(UserService);
            this.datePipe = injector.get(DatePipe);
            this.subscriptions.push(this.studySetupService.changeEmitted$.subscribe(
                eventInfo => {
                    this.parseEventInfoInBase(eventInfo);
                }));
        } catch (e) {
            console.log('Failed initializing dependencies', e)
        }
    }

    ngOnInit() {

    }


    ngOnDestroy() {
        this.subscriptions.forEach(
            x => {
                x.unsubscribe();
                x.closed;
            }
        )
    }

        /**
   * Setting date value when effective date or version date is selected
   */
        setDateSelection(updatedValue, row, columnName) {
            if (updatedValue === '' || updatedValue == null) {
              row[columnName] = null;
            } else {
                if(columnName ==='effectiveDate'){
                    row[columnName] = moment(updatedValue).toDate(); 
                }else{
                    const transformedDate = this.datePipe.transform(updatedValue, "MM/dd/yyyy")
                    row[columnName] = transformedDate;
                }
            }
          }

      /**
   * Returns the event name / label string for display on the ui
   * @param objectType the code to check
   */
      getNameForDropDownLabel(objectType: Code, objectTypes: Code[]): string {
        if (objectTypes && objectTypes.length > 0) {
          const match = objectTypes.find(eachType => eachType.id === objectType.id);
          if (match) {
            return match.name;
          }
        }
        return '';
      }
        
  /**
   * Function for the drop down to select the correct code
   * @param a first compare item
   * @param b second compare item
   */
  compareCodeFn(a, b) {
    return a && b && a.id === b.id;
  }



    /**
     * Handles the Event retrieved from the Queue
     * @param eventArg The Event Arg passed in by the event
     */
    private parseEventInfoInBase(eventArg: EventArg) {
        if (eventArg.eventName == globalConst.EVENT_SAVE_ACTION ||
            eventArg.eventName == globalConst.EVENT_CANCEL_ACTION) {
            this.clearErrorMessages();
        }
    }


    /**
      * HAndler for the click event on the row on form elements
      * @param $event The click event raised
      */
    ignoreClick($event: Event) {
        if ($event) {
            $event.stopPropagation();
        }

    }


    /***   ERROR HANDLING  ***************************** */


    showErrorMessageBlock(): boolean {
        return this.errorMessages && this.errorMessages.length > 0
    }


    /**
   * Handler for the clear errorMessages
  */
    clearErrorMessages() {
        this.errorMessages = [];
        //  this.groupedErrors.clear();
        this.studySetupService.emitErrorMessagesChange(this.errorMessages);
    }



    /**
     *  Group the error Message by the Id
     *
    */
    groupErrors(errorMessages: ErrorMessage[]) {
        this.errorMessages = errorMessages
        if (this.errorMessages.length > 0) {
            this.studySetupService.emitErrorMessagesChange(this.errorMessages);
        }
    }


    /**
     * Evalutes if the event has a validation error
     * @param idToEvaluate The ID to evaluate
     */
    hasError(idToEvaluate: any): boolean {
        let containsError = false;
        if (this.errorMessages.length > 0) {
            const match = this.errorMessages.find(errMsg => errMsg && errMsg.id == idToEvaluate);
            containsError = match != null;
        }
        return containsError;
    }



    /**
     * Main method to check for Errors in the errorResponse  object with Error Code = 400
     * The errors if they exist, will be appended to the existing array.
     * @param id The id of the object to be used to set the id on the error
     * @param error The actual error object
     * @param errorMessages The existing array of errorMessages to which the new error is pushed
     */
    checkValidationErrorInResponse(id: any, error, errorMessages: ErrorMessage[]): boolean {
        let isValidationError = false;
        // Checking specifically for error.code === 400 (validation errors).
        // This can be updated as need to locate the messages in the correct hierarchy
        if (error && error.error) {
            if (typeof error.error == 'string') {
                const errObj = JSON.parse(error.error);
                error.error = errObj;
            }
            if (error.error.code && error.error.code === 400) {
                const errObj: ErrorMessage = {
                    id: id,
                    message: error.error.message
                }
                errorMessages.push(errObj)
                isValidationError = true;
            }
        }
        return isValidationError;
    }


    /**
     * Parses and returns the message for the info click
     * @param userPk The User Primary Key
     * @param modifiedTs The Date Time for the modified record
     */
    getInfoMessageString(userPk: string, modifiedTs: any): string {
        let message = '';
        message = 'Last updated';
        let lastModifiedDate = '';
        const lastUserName = this.getUserNameFromPrimaryKey(userPk);

        message += ' by ' + lastUserName;
        if (modifiedTs) {
            lastModifiedDate = ' on ' + moment(new Date(modifiedTs)).format('MM/DD/YYYY HH:mm:ss');
        }
        message += lastModifiedDate;
        return message;
    }

    /**
     * Gets the user name from the service using the Primary Key
     * @param userPk The userPk string
     */
    getUserNameFromPrimaryKey(userPk: string) {
        let username = userPk; // 'unknown';
        if (userPk && userPk.trim().length > 0) {
            // force to be numeric
            const userPkNum: any = +userPk;
            if (!isNaN(userPkNum)) {
                this.usrService.getPersonForId(userPkNum)
                    .subscribe(result => {
                        username = result.lastName + ', ' + result.firstName;
                    }, (error) => {
                        username = userPk + '(name not found)';
                    }, () => {
                        // console.log("Complete subscription ")
                    });
            }
        }
        return username;
    }


}
